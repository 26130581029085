import _jquery from "jquery";
import _moment from "moment";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// jquery.daterangepicker.js
// author : Chunlong Liu
// license : MIT
// www.jszen.com
(function (factory) {
  // CommonJS. Register as a module
  exports = factory(_jquery, _moment);
})(function ($, moment) {
  'use strict';

  $.dateRangePickerLanguages = {
    "default": //default language: English
    {
      "selected": "Selected:",
      "day": "Day",
      "days": "Days",
      "apply": "Close",
      "week-1": "mo",
      "week-2": "tu",
      "week-3": "we",
      "week-4": "th",
      "week-5": "fr",
      "week-6": "sa",
      "week-7": "su",
      "week-number": "W",
      "month-name": ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
      "shortcuts": "Shortcuts",
      "custom-values": "Custom Values",
      "past": "Past",
      "following": "Following",
      "previous": "Previous",
      "prev-week": "Week",
      "prev-month": "Month",
      "prev-year": "Year",
      "next": "Next",
      "next-week": "Week",
      "next-month": "Month",
      "next-year": "Year",
      "less-than": "Date range should not be more than %d days",
      "more-than": "Date range should not be less than %d days",
      "default-more": "Please select a date range longer than %d days",
      "default-single": "Please select a date",
      "default-less": "Please select a date range less than %d days",
      "default-range": "Please select a date range between %d and %d days",
      "default-default": "Please select a date range",
      "time": "Time",
      "hour": "Hour",
      "minute": "Minute"
    },
    "id": {
      "selected": "Terpilih:",
      "day": "Hari",
      "days": "Hari",
      "apply": "Tutup",
      "week-1": "sen",
      "week-2": "sel",
      "week-3": "rab",
      "week-4": "kam",
      "week-5": "jum",
      "week-6": "sab",
      "week-7": "min",
      "week-number": "W",
      "month-name": ["januari", "februari", "maret", "april", "mei", "juni", "juli", "agustus", "september", "oktober", "november", "desember"],
      "shortcuts": "Pintas",
      "custom-values": "Nilai yang ditentukan",
      "past": "Yang Lalu",
      "following": "Mengikuti",
      "previous": "Sebelumnya",
      "prev-week": "Minggu",
      "prev-month": "Bulan",
      "prev-year": "Tahun",
      "next": "Selanjutnya",
      "next-week": "Minggu",
      "next-month": "Bulan",
      "next-year": "Tahun",
      "less-than": "Tanggal harus lebih dari %d hari",
      "more-than": "Tanggal harus kurang dari %d hari",
      "default-more": "Jarak tanggal harus lebih lama dari %d hari",
      "default-single": "Silakan pilih tanggal",
      "default-less": "Jarak rentang tanggal tidak boleh lebih lama dari %d hari",
      "default-range": "Rentang tanggal harus antara %d dan %d hari",
      "default-default": "Silakan pilih rentang tanggal",
      "time": "Waktu",
      "hour": "Jam",
      "minute": "Menit"
    },
    "az": {
      "selected": "Se\xE7ildi:",
      "day": " g\xFCn",
      "days": " g\xFCn",
      "apply": "t\u0259tbiq",
      "week-1": "1",
      "week-2": "2",
      "week-3": "3",
      "week-4": "4",
      "week-5": "5",
      "week-6": "6",
      "week-7": "7",
      "month-name": ["yanvar", "fevral", "mart", "aprel", "may", "iyun", "iyul", "avqust", "sentyabr", "oktyabr", "noyabr", "dekabr"],
      "shortcuts": "Q\u0131sayollar",
      "past": "Ke\xE7mi\u015F",
      "following": "N\xF6vb\u0259ti",
      "previous": "&nbsp;&nbsp;&nbsp;",
      "prev-week": "\xD6nc\u0259ki h\u0259ft\u0259",
      "prev-month": "\xD6nc\u0259ki ay",
      "prev-year": "\xD6nc\u0259ki il",
      "next": "&nbsp;&nbsp;&nbsp;",
      "next-week": "N\xF6vb\u0259ti h\u0259ft\u0259",
      "next-month": "N\xF6vb\u0259ti ay",
      "next-year": "N\xF6vb\u0259ti il",
      "less-than": "Tarix aral\u0131\u011F\u0131 %d g\xFCnd\u0259n \xE7ox olmamal\u0131d\u0131r",
      "more-than": "Tarix aral\u0131\u011F\u0131 %d g\xFCnd\u0259n az olmamal\u0131d\u0131r",
      "default-more": "%d g\xFCnd\u0259n \xE7ox bir tarix se\xE7in",
      "default-single": "Tarix se\xE7in",
      "default-less": "%d g\xFCnd\u0259n az bir tarix se\xE7in",
      "default-range": "%d v\u0259 %d g\xFCn aral\u0131\u011F\u0131nda tarixl\u0259r se\xE7in",
      "default-default": "Tarix aral\u0131\u011F\u0131 se\xE7in"
    },
    "bg": {
      "selected": "\u0418\u0437\u0431\u0440\u0430\u043D\u043E:",
      "day": "\u0414\u0435\u043D",
      "days": "\u0414\u043D\u0438",
      "apply": "\u0417\u0430\u0442\u0432\u043E\u0440\u0438",
      "week-1": "\u043F\u043D",
      "week-2": "\u0432\u0442",
      "week-3": "\u0441\u0440",
      "week-4": "\u0447\u0442",
      "week-5": "\u043F\u0442",
      "week-6": "\u0441\u0431",
      "week-7": "\u043D\u0434",
      "week-number": "\u0421",
      "month-name": ["\u044F\u043D\u0443\u0430\u0440\u0438", "\u0444\u0435\u0432\u0440\u0443\u0430\u0440\u0438", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440\u0438\u043B", "\u043C\u0430\u0439", "\u044E\u043D\u0438", "\u044E\u043B\u0438", "\u0430\u0432\u0433\u0443\u0441\u0442", "\u0441\u0435\u043F\u0442\u0435\u043C\u0432\u0440\u0438", "\u043E\u043A\u0442\u043E\u043C\u0432\u0440\u0438", "\u043D\u043E\u0435\u043C\u0432\u0440\u0438", "\u0434\u0435\u043A\u0435\u043C\u0432\u0440\u0438"],
      "shortcuts": "\u041F\u0440\u0435\u043A\u0438 \u043F\u044A\u0442\u0438\u0449\u0430",
      "custom-values": "\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u0438\u0437\u0438\u0440\u0430\u043D\u0438 \u0441\u0442\u043E\u0439\u043D\u043E\u0441\u0442\u0438",
      "past": "\u041C\u0438\u043D\u0430\u043B",
      "following": "\u0421\u043B\u0435\u0434\u0432\u0430\u0449",
      "previous": "\u041F\u0440\u0435\u0434\u0438\u0448\u0435\u043D",
      "prev-week": "\u0421\u0435\u0434\u043C\u0438\u0446\u0430",
      "prev-month": "\u041C\u0435\u0441\u0435\u0446",
      "prev-year": "\u0413\u043E\u0434\u0438\u043D\u0430",
      "next": "\u0421\u043B\u0435\u0434\u0432\u0430\u0449",
      "next-week": "\u0421\u0435\u0434\u043C\u0438\u0446\u0430",
      "next-month": "\u041C\u0435\u0441\u0435\u0446",
      "next-year": "\u0413\u043E\u0434\u0438\u043D\u0430",
      "less-than": "\u041F\u0435\u0440\u0438\u043E\u0434\u044A\u0442 \u043E\u0442 \u0432\u0440\u0435\u043C\u0435 \u043D\u0435 \u0442\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0435 \u043F\u043E\u0432\u0435\u0447\u0435 \u043E\u0442 %d \u0434\u043D\u0438",
      "more-than": "\u041F\u0435\u0440\u0438\u043E\u0434\u044A\u0442 \u043E\u0442 \u0432\u0440\u0435\u043C\u0435 \u043D\u0435 \u0442\u0440\u044F\u0431\u0432\u0430 \u0434\u0430 \u0435 \u043F\u043E-\u043C\u0430\u043B\u043A\u043E \u043E\u0442 %d \u0434\u043D\u0438",
      "default-more": "\u041C\u043E\u043B\u044F \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434 \u043F\u043E-\u0434\u044A\u043B\u044A\u0433 \u043E\u0442 %d \u0434\u043D\u0438",
      "default-single": "\u041C\u043E\u043B\u044F \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0434\u0430\u0442\u0430",
      "default-less": "\u041C\u043E\u043B\u044F \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434 \u043F\u043E-\u043A\u044A\u0441 \u043E\u0442 %d \u0434\u043D\u0438",
      "default-range": "\u041C\u043E\u043B\u044F \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434 \u043C\u0435\u0436\u0434\u0443 %d \u0438 %d \u0434\u043D\u0438",
      "default-default": "\u041C\u043E\u043B\u044F \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434",
      "time": "\u0412\u0440\u0435\u043C\u0435",
      "hour": "\u0427\u0430\u0441",
      "minute": "\u041C\u0438\u043D\u0443\u0442\u0430"
    },
    "cn": //simplified chinese
    {
      "selected": "\u5DF2\u9009\u62E9:",
      "day": "\u5929",
      "days": "\u5929",
      "apply": "\u786E\u5B9A",
      "week-1": "\u4E00",
      "week-2": "\u4E8C",
      "week-3": "\u4E09",
      "week-4": "\u56DB",
      "week-5": "\u4E94",
      "week-6": "\u516D",
      "week-7": "\u65E5",
      "week-number": "\u5468",
      "month-name": ["\u4E00\u6708", "\u4E8C\u6708", "\u4E09\u6708", "\u56DB\u6708", "\u4E94\u6708", "\u516D\u6708", "\u4E03\u6708", "\u516B\u6708", "\u4E5D\u6708", "\u5341\u6708", "\u5341\u4E00\u6708", "\u5341\u4E8C\u6708"],
      "shortcuts": "\u5FEB\u6377\u9009\u62E9",
      "past": "\u8FC7\u53BB",
      "following": "\u5C06\u6765",
      "previous": "&nbsp;&nbsp;&nbsp;",
      "prev-week": "\u4E0A\u5468",
      "prev-month": "\u4E0A\u4E2A\u6708",
      "prev-year": "\u53BB\u5E74",
      "next": "&nbsp;&nbsp;&nbsp;",
      "next-week": "\u4E0B\u5468",
      "next-month": "\u4E0B\u4E2A\u6708",
      "next-year": "\u660E\u5E74",
      "less-than": "\u6240\u9009\u65E5\u671F\u8303\u56F4\u4E0D\u80FD\u5927\u4E8E%d\u5929",
      "more-than": "\u6240\u9009\u65E5\u671F\u8303\u56F4\u4E0D\u80FD\u5C0F\u4E8E%d\u5929",
      "default-more": "\u8BF7\u9009\u62E9\u5927\u4E8E%d\u5929\u7684\u65E5\u671F\u8303\u56F4",
      "default-less": "\u8BF7\u9009\u62E9\u5C0F\u4E8E%d\u5929\u7684\u65E5\u671F\u8303\u56F4",
      "default-range": "\u8BF7\u9009\u62E9%d\u5929\u5230%d\u5929\u7684\u65E5\u671F\u8303\u56F4",
      "default-single": "\u8BF7\u9009\u62E9\u4E00\u4E2A\u65E5\u671F",
      "default-default": "\u8BF7\u9009\u62E9\u4E00\u4E2A\u65E5\u671F\u8303\u56F4",
      "time": "\u65F6\u95F4",
      "hour": "\u5C0F\u65F6",
      "minute": "\u5206\u949F"
    },
    "cz": {
      "selected": "Vybr\xE1no:",
      "day": "Den",
      "days": "Dny",
      "apply": "Zav\u0159\xEDt",
      "week-1": "po",
      "week-2": "\xFAt",
      "week-3": "st",
      "week-4": "\u010Dt",
      "week-5": "p\xE1",
      "week-6": "so",
      "week-7": "ne",
      "month-name": ["leden", "\xFAnor", "b\u0159ezen", "duben", "kv\u011Bten", "\u010Derven", "\u010Dervenec", "srpen", "z\xE1\u0159\xED", "\u0159\xEDjen", "listopad", "prosinec"],
      "shortcuts": "Zkratky",
      "past": "po",
      "following": "n\xE1sleduj\xEDc\xED",
      "previous": "p\u0159edchoz\xED",
      "prev-week": "t\xFDden",
      "prev-month": "m\u011Bs\xEDc",
      "prev-year": "rok",
      "next": "dal\u0161\xED",
      "next-week": "t\xFDden",
      "next-month": "m\u011Bs\xEDc",
      "next-year": "rok",
      "less-than": "Rozsah data by nem\u011Bl b\xFDt v\u011Bt\u0161\xED ne\u017E %d dn\u016F",
      "more-than": "Rozsah data by nem\u011Bl b\xFDt men\u0161\xED ne\u017E %d dn\u016F",
      "default-more": "Pros\xEDm zvolte rozsah data v\u011Bt\u0161\xED ne\u017E %d dn\u016F",
      "default-single": "Pros\xEDm zvolte datum",
      "default-less": "Pros\xEDm zvolte rozsah data men\u0161\xED ne\u017E %d dn\u016F",
      "default-range": "Pros\xEDm zvolte rozsah data mezi %d a %d dny",
      "default-default": "Pros\xEDm zvolte rozsah data"
    },
    "de": {
      "selected": "Auswahl:",
      "day": "Tag",
      "days": "Tage",
      "apply": "Schlie\xDFen",
      "week-1": "mo",
      "week-2": "di",
      "week-3": "mi",
      "week-4": "do",
      "week-5": "fr",
      "week-6": "sa",
      "week-7": "so",
      "month-name": ["januar", "februar", "m\xE4rz", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "dezember"],
      "shortcuts": "Schnellwahl",
      "past": "Vorherige",
      "following": "Folgende",
      "previous": "Vorherige",
      "prev-week": "Woche",
      "prev-month": "Monat",
      "prev-year": "Jahr",
      "next": "N\xE4chste",
      "next-week": "Woche",
      "next-month": "Monat",
      "next-year": "Jahr",
      "less-than": "Datumsbereich darf nicht gr\xF6\xDFer sein als %d Tage",
      "more-than": "Datumsbereich darf nicht kleiner sein als %d Tage",
      "default-more": "Bitte mindestens %d Tage ausw\xE4hlen",
      "default-single": "Bitte ein Datum ausw\xE4hlen",
      "default-less": "Bitte weniger als %d Tage ausw\xE4hlen",
      "default-range": "Bitte einen Datumsbereich zwischen %d und %d Tagen ausw\xE4hlen",
      "default-default": "Bitte ein Start- und Enddatum ausw\xE4hlen",
      "Time": "Zeit",
      "hour": "Stunde",
      "minute": "Minute"
    },
    "es": {
      "selected": "Seleccionado:",
      "day": "D\xEDa",
      "days": "D\xEDas",
      "apply": "Cerrar",
      "week-1": "lu",
      "week-2": "ma",
      "week-3": "mi",
      "week-4": "ju",
      "week-5": "vi",
      "week-6": "sa",
      "week-7": "do",
      "month-name": ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      "shortcuts": "Accesos directos",
      "past": "Pasado",
      "following": "Siguiente",
      "previous": "Anterior",
      "prev-week": "Semana",
      "prev-month": "Mes",
      "prev-year": "A\xF1o",
      "next": "Siguiente",
      "next-week": "Semana",
      "next-month": "Mes",
      "next-year": "A\xF1o",
      "less-than": "El rango no deber\xEDa ser mayor de %d d\xEDas",
      "more-than": "El rango no deber\xEDa ser menor de %d d\xEDas",
      "default-more": "Por favor selecciona un rango mayor a %d d\xEDas",
      "default-single": "Por favor selecciona un d\xEDa",
      "default-less": "Por favor selecciona un rango menor a %d d\xEDas",
      "default-range": "Por favor selecciona un rango entre %d y %d d\xEDas",
      "default-default": "Por favor selecciona un rango de fechas."
    },
    "fr": {
      "selected": "S\xE9lection:",
      "day": "Jour",
      "days": "Jours",
      "apply": "Fermer",
      "week-1": "lu",
      "week-2": "ma",
      "week-3": "me",
      "week-4": "je",
      "week-5": "ve",
      "week-6": "sa",
      "week-7": "di",
      "month-name": ["janvier", "f\xE9vrier", "mars", "avril", "mai", "juin", "juillet", "ao\xFBt", "septembre", "octobre", "novembre", "d\xE9cembre"],
      "shortcuts": "Raccourcis",
      "past": "Pass\xE9",
      "following": "Suivant",
      "previous": "Pr\xE9c\xE9dent",
      "prev-week": "Semaine",
      "prev-month": "Mois",
      "prev-year": "Ann\xE9e",
      "next": "Suivant",
      "next-week": "Semaine",
      "next-month": "Mois",
      "next-year": "Ann\xE9e",
      "less-than": "L'intervalle ne doit pas \xEAtre sup\xE9rieure \xE0 %d jours",
      "more-than": "L'intervalle ne doit pas \xEAtre inf\xE9rieure \xE0 %d jours",
      "default-more": "Merci de choisir une intervalle sup\xE9rieure \xE0 %d jours",
      "default-single": "Merci de choisir une date",
      "default-less": "Merci de choisir une intervalle inf\xE9rieure %d jours",
      "default-range": "Merci de choisir une intervalle comprise entre %d et %d jours",
      "default-default": "Merci de choisir une date"
    },
    "hu": {
      "selected": "Kiv\xE1lasztva:",
      "day": "Nap",
      "days": "Nap",
      "apply": "Ok",
      "week-1": "h",
      "week-2": "k",
      "week-3": "sz",
      "week-4": "cs",
      "week-5": "p",
      "week-6": "sz",
      "week-7": "v",
      "month-name": ["janu\xE1r", "febru\xE1r", "m\xE1rcius", "\xE1prilis", "m\xE1jus", "j\xFAnius", "j\xFAlius", "augusztus", "szeptember", "okt\xF3ber", "november", "december"],
      "shortcuts": "Gyorsv\xE1laszt\xF3",
      "past": "M\xFAlt",
      "following": "K\xF6vetkez\u0151",
      "previous": "El\u0151z\u0151",
      "prev-week": "H\xE9t",
      "prev-month": "H\xF3nap",
      "prev-year": "\xC9v",
      "next": "K\xF6vetkez\u0151",
      "next-week": "H\xE9t",
      "next-month": "H\xF3nap",
      "next-year": "\xC9v",
      "less-than": "A kiv\xE1laszt\xE1s nem lehet t\xF6bb %d napn\xE1l",
      "more-than": "A kiv\xE1laszt\xE1s nem lehet t\xF6bb %d napn\xE1l",
      "default-more": "V\xE1lassz ki egy id\u0151szakot ami hosszabb mint %d nap",
      "default-single": "V\xE1lassz egy napot",
      "default-less": "V\xE1lassz ki egy id\u0151szakot ami r\xF6videbb mint %d nap",
      "default-range": "V\xE1lassz ki egy %d - %d nap hossz\xFA id\u0151szakot",
      "default-default": "V\xE1lassz ki egy id\u0151szakot"
    },
    "it": {
      "selected": "Selezionati:",
      "day": "Giorno",
      "days": "Giorni",
      "apply": "Chiudi",
      "week-1": "lu",
      "week-2": "ma",
      "week-3": "me",
      "week-4": "gi",
      "week-5": "ve",
      "week-6": "sa",
      "week-7": "do",
      "month-name": ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
      "shortcuts": "Scorciatoie",
      "past": "Scorso",
      "following": "Successivo",
      "previous": "Precedente",
      "prev-week": "Settimana",
      "prev-month": "Mese",
      "prev-year": "Anno",
      "next": "Prossimo",
      "next-week": "Settimana",
      "next-month": "Mese",
      "next-year": "Anno",
      "less-than": "L'intervallo non dev'essere maggiore di %d giorni",
      "more-than": "L'intervallo non dev'essere minore di %d giorni",
      "default-more": "Seleziona un intervallo maggiore di %d giorni",
      "default-single": "Seleziona una data",
      "default-less": "Seleziona un intervallo minore di %d giorni",
      "default-range": "Seleziona un intervallo compreso tra i %d e i %d giorni",
      "default-default": "Seleziona un intervallo di date"
    },
    "ko": {
      "selected": "\uAE30\uAC04:",
      "day": "\uC77C",
      "days": "\uC77C\uAC04",
      "apply": "\uB2EB\uAE30",
      "week-1": "\uC6D4",
      "week-2": "\uD654",
      "week-3": "\uC218",
      "week-4": "\uBAA9",
      "week-5": "\uAE08",
      "week-6": "\uD1A0",
      "week-7": "\uC77C",
      "week-number": "\uC8FC",
      "month-name": ["1\uC6D4", "2\uC6D4", "3\uC6D4", "4\uC6D4", "5\uC6D4", "6\uC6D4", "7\uC6D4", "8\uC6D4", "9\uC6D4", "10\uC6D4", "11\uC6D4", "12\uC6D4"],
      "shortcuts": "\uB2E8\uCD95\uD0A4\uB4E4",
      "past": "\uC9C0\uB09C(\uC624\uB298\uAE30\uC900)",
      "following": "\uC774\uD6C4(\uC624\uB298\uAE30\uC900)",
      "previous": "\uC774\uC804",
      "prev-week": "1\uC8FC",
      "prev-month": "1\uB2EC",
      "prev-year": "1\uB144",
      "next": "\uB2E4\uC74C",
      "next-week": "1\uC8FC",
      "next-month": "1\uB2EC",
      "next-year": "1\uB144",
      "less-than": "\uB0A0\uC9DC \uBC94\uC704\uB294 %d \uC77C\uBCF4\uB2E4 \uB9CE\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4",
      "more-than": "\uB0A0\uC9DC \uBC94\uC704\uB294 %d \uC77C\uBCF4\uB2E4 \uC791\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4",
      "default-more": "\uB0A0\uC9DC \uBC94\uC704\uB97C %d \uC77C\uBCF4\uB2E4 \uAE38\uAC8C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694",
      "default-single": "\uB0A0\uC9DC\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694",
      "default-less": "%d \uC77C\uBCF4\uB2E4 \uC791\uC740 \uB0A0\uC9DC\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694",
      "default-range": "%d\uC640 %d \uC77C \uC0AC\uC774\uC758 \uB0A0\uC9DC \uBC94\uC704\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694",
      "default-default": "\uB0A0\uC9DC \uBC94\uC704\uB97C \uC120\uD0DD\uD574 \uC8FC\uC138\uC694",
      "time": "\uC2DC\uAC01",
      "hour": "\uC2DC",
      "minute": "\uBD84"
    },
    "no": {
      "selected": "Valgt:",
      "day": "Dag",
      "days": "Dager",
      "apply": "Lukk",
      "week-1": "ma",
      "week-2": "ti",
      "week-3": "on",
      "week-4": "to",
      "week-5": "fr",
      "week-6": "l\xF8",
      "week-7": "s\xF8",
      "month-name": ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
      "shortcuts": "Snarveier",
      "custom-values": "Egendefinerte Verdier",
      "past": "Over",
      // Not quite sure about the context of this one
      "following": "F\xF8lger",
      "previous": "Forrige",
      "prev-week": "Uke",
      "prev-month": "M\xE5ned",
      "prev-year": "\xC5r",
      "next": "Neste",
      "next-week": "Uke",
      "next-month": "M\xE5ned",
      "next-year": "\xC5r",
      "less-than": "Datoperioden skal ikkje v\xE6re lengre enn %d dager",
      "more-than": "Datoperioden skal ikkje v\xE6re kortere enn %d dager",
      "default-more": "Vennligst velg ein datoperiode lengre enn %d dager",
      "default-single": "Vennligst velg ein dato",
      "default-less": "Vennligst velg ein datoperiode mindre enn %d dager",
      "default-range": "Vennligst velg ein datoperiode mellom %d og %d dager",
      "default-default": "Vennligst velg ein datoperiode",
      "time": "Tid",
      "hour": "Time",
      "minute": "Minutter"
    },
    "nl": {
      "selected": "Geselecteerd:",
      "day": "Dag",
      "days": "Dagen",
      "apply": "Ok",
      "week-1": "ma",
      "week-2": "di",
      "week-3": "wo",
      "week-4": "do",
      "week-5": "vr",
      "week-6": "za",
      "week-7": "zo",
      "month-name": ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
      "shortcuts": "Snelkoppelingen",
      "custom-values": "Aangepaste waarden",
      "past": "Verleden",
      "following": "Komend",
      "previous": "Vorige",
      "prev-week": "Week",
      "prev-month": "Maand",
      "prev-year": "Jaar",
      "next": "Volgende",
      "next-week": "Week",
      "next-month": "Maand",
      "next-year": "Jaar",
      "less-than": "Interval moet langer dan %d dagen zijn",
      "more-than": "Interval mag niet minder dan %d dagen zijn",
      "default-more": "Selecteer een interval langer dan %dagen",
      "default-single": "Selecteer een datum",
      "default-less": "Selecteer een interval minder dan %d dagen",
      "default-range": "Selecteer een interval tussen %d en %d dagen",
      "default-default": "Selecteer een interval",
      "time": "Tijd",
      "hour": "Uur",
      "minute": "Minuut"
    },
    "ru": {
      "selected": "\u0412\u044B\u0431\u0440\u0430\u043D\u043E:",
      "day": "\u0414\u0435\u043D\u044C",
      "days": "\u0414\u043D\u0435\u0439",
      "apply": "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C",
      "week-1": "\u043F\u043D",
      "week-2": "\u0432\u0442",
      "week-3": "\u0441\u0440",
      "week-4": "\u0447\u0442",
      "week-5": "\u043F\u0442",
      "week-6": "\u0441\u0431",
      "week-7": "\u0432\u0441",
      "month-name": ["\u044F\u043D\u0432\u0430\u0440\u044C", "\u0444\u0435\u0432\u0440\u0430\u043B\u044C", "\u043C\u0430\u0440\u0442", "\u0430\u043F\u0440\u0435\u043B\u044C", "\u043C\u0430\u0439", "\u0438\u044E\u043D\u044C", "\u0438\u044E\u043B\u044C", "\u0430\u0432\u0433\u0443\u0441\u0442", "\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044C", "\u043E\u043A\u0442\u044F\u0431\u0440\u044C", "\u043D\u043E\u044F\u0431\u0440\u044C", "\u0434\u0435\u043A\u0430\u0431\u0440\u044C"],
      "shortcuts": "\u0411\u044B\u0441\u0442\u0440\u044B\u0439 \u0432\u044B\u0431\u043E\u0440",
      "custom-values": "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F",
      "past": "\u041F\u0440\u043E\u0448\u0435\u0434\u0448\u0438\u0435",
      "following": "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0435",
      "previous": "&nbsp;&nbsp;&nbsp;",
      "prev-week": "\u041D\u0435\u0434\u0435\u043B\u044F",
      "prev-month": "\u041C\u0435\u0441\u044F\u0446",
      "prev-year": "\u0413\u043E\u0434",
      "next": "&nbsp;&nbsp;&nbsp;",
      "next-week": "\u041D\u0435\u0434\u0435\u043B\u044F",
      "next-month": "\u041C\u0435\u0441\u044F\u0446",
      "next-year": "\u0413\u043E\u0434",
      "less-than": "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435 %d \u0434\u043D\u0435\u0439",
      "more-than": "\u0414\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u043C\u0435\u043D\u044C\u0448\u0435 %d \u0434\u043D\u0435\u0439",
      "default-more": "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u0431\u043E\u043B\u044C\u0448\u0435 %d \u0434\u043D\u0435\u0439",
      "default-single": "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0430\u0442\u0443",
      "default-less": "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u043C\u0435\u043D\u044C\u0448\u0435 %d \u0434\u043D\u0435\u0439",
      "default-range": "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0438\u0430\u043F\u0430\u0437\u043E\u043D \u043C\u0435\u0436\u0434\u0443 %d \u0438 %d \u0434\u043D\u044F\u043C\u0438",
      "default-default": "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0434\u0438\u0430\u043F\u0430\u0437\u043E\u043D",
      "time": "\u0412\u0440\u0435\u043C\u044F",
      "hour": "\u0427\u0430\u0441\u044B",
      "minute": "\u041C\u0438\u043D\u0443\u0442\u044B"
    },
    "pl": {
      "selected": "Wybrany:",
      "day": "Dzie\u0144",
      "days": "Dni",
      "apply": "Zamknij",
      "week-1": "pon",
      "week-2": "wt",
      "week-3": "\u015Br",
      "week-4": "czw",
      "week-5": "pt",
      "week-6": "so",
      "week-7": "nd",
      "month-name": ["stycze\u0144", "luty", "marzec", "kwiecie\u0144", "maj", "czerwiec", "lipiec", "sierpie\u0144", "wrzesie\u0144", "pa\u017Adziernik", "listopad", "grudzie\u0144"],
      "shortcuts": "Skr\xF3ty",
      "custom-values": "Niestandardowe warto\u015Bci",
      "past": "Przesz\u0142e",
      "following": "Nast\u0119pne",
      "previous": "Poprzednie",
      "prev-week": "tydzie\u0144",
      "prev-month": "miesi\u0105c",
      "prev-year": "rok",
      "next": "Nast\u0119pny",
      "next-week": "tydzie\u0144",
      "next-month": "miesi\u0105c",
      "next-year": "rok",
      "less-than": "Okres nie powinien by\u0107 d\u0142u\u017Cszy ni\u017C %d dni",
      "more-than": "Okres nie powinien by\u0107 kr\xF3tszy ni\u017C  %d ni",
      "default-more": "Wybierz okres d\u0142u\u017Cszy ni\u017C %d dni",
      "default-single": "Wybierz dat\u0119",
      "default-less": "Wybierz okres kr\xF3tszy ni\u017C %d dni",
      "default-range": "Wybierz okres trwaj\u0105cy od %d do %d dni",
      "default-default": "Wybierz okres",
      "time": "Czas",
      "hour": "Godzina",
      "minute": "Minuta"
    },
    "se": {
      "selected": "Vald:",
      "day": "dag",
      "days": "dagar",
      "apply": "godk\xE4nn",
      "week-1": "ma",
      "week-2": "ti",
      "week-3": "on",
      "week-4": "to",
      "week-5": "fr",
      "week-6": "l\xF6",
      "week-7": "s\xF6",
      "month-name": ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
      "shortcuts": "genv\xE4gar",
      "custom-values": "Anpassade v\xE4rden",
      "past": "\xF6ver",
      "following": "f\xF6ljande",
      "previous": "f\xF6rra",
      "prev-week": "vecka",
      "prev-month": "m\xE5nad",
      "prev-year": "\xE5r",
      "next": "n\xE4sta",
      "next-week": "vecka",
      "next-month": "m\xE5ned",
      "next-year": "\xE5r",
      "less-than": "Datumintervall b\xF6r inte vara mindre \xE4n %d dagar",
      "more-than": "Datumintervall b\xF6r inte vara mer \xE4n %d dagar",
      "default-more": "V\xE4lj ett datumintervall l\xE4ngre \xE4n %d dagar",
      "default-single": "V\xE4lj ett datum",
      "default-less": "V\xE4lj ett datumintervall mindre \xE4n %d dagar",
      "default-range": "V\xE4lj ett datumintervall mellan %d och %d dagar",
      "default-default": "V\xE4lj ett datumintervall",
      "time": "tid",
      "hour": "timme",
      "minute": "minut"
    },
    "pt": //Portuguese (European)
    {
      "selected": "Selecionado:",
      "day": "Dia",
      "days": "Dias",
      "apply": "Fechar",
      "week-1": "seg",
      "week-2": "ter",
      "week-3": "qua",
      "week-4": "qui",
      "week-5": "sex",
      "week-6": "sab",
      "week-7": "dom",
      "week-number": "N",
      "month-name": ["janeiro", "fevereiro", "mar\xE7o", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
      "shortcuts": "Atalhos",
      "custom-values": "Valores Personalizados",
      "past": "Passado",
      "following": "Seguinte",
      "previous": "Anterior",
      "prev-week": "Semana",
      "prev-month": "M\xEAs",
      "prev-year": "Ano",
      "next": "Pr\xF3ximo",
      "next-week": "Pr\xF3xima Semana",
      "next-month": "Pr\xF3ximo M\xEAs",
      "next-year": "Pr\xF3ximo Ano",
      "less-than": "O per\xEDodo selecionado n\xE3o deve ser maior que %d dias",
      "more-than": "O per\xEDodo selecionado n\xE3o deve ser menor que %d dias",
      "default-more": "Selecione um per\xEDodo superior a %d dias",
      "default-single": "Selecione uma data",
      "default-less": "Selecione um per\xEDodo inferior a %d dias",
      "default-range": "Selecione um per\xEDodo de %d a %d dias",
      "default-default": "Selecione um per\xEDodo",
      "time": "Tempo",
      "hour": "Hora",
      "minute": "Minuto"
    },
    "tc": // traditional chinese
    {
      "selected": "\u5DF2\u9078\u64C7:",
      "day": "\u5929",
      "days": "\u5929",
      "apply": "\u78BA\u5B9A",
      "week-1": "\u4E00",
      "week-2": "\u4E8C",
      "week-3": "\u4E09",
      "week-4": "\u56DB",
      "week-5": "\u4E94",
      "week-6": "\u516D",
      "week-7": "\u65E5",
      "week-number": "\u5468",
      "month-name": ["\u4E00\u6708", "\u4E8C\u6708", "\u4E09\u6708", "\u56DB\u6708", "\u4E94\u6708", "\u516D\u6708", "\u4E03\u6708", "\u516B\u6708", "\u4E5D\u6708", "\u5341\u6708", "\u5341\u4E00\u6708", "\u5341\u4E8C\u6708"],
      "shortcuts": "\u5FEB\u901F\u9078\u64C7",
      "past": "\u904E\u53BB",
      "following": "\u5C07\u4F86",
      "previous": "&nbsp;&nbsp;&nbsp;",
      "prev-week": "\u4E0A\u9031",
      "prev-month": "\u4E0A\u500B\u6708",
      "prev-year": "\u53BB\u5E74",
      "next": "&nbsp;&nbsp;&nbsp;",
      "next-week": "\u4E0B\u5468",
      "next-month": "\u4E0B\u500B\u6708",
      "next-year": "\u660E\u5E74",
      "less-than": "\u6240\u9078\u65E5\u671F\u7BC4\u570D\u4E0D\u80FD\u5927\u65BC%d\u5929",
      "more-than": "\u6240\u9078\u65E5\u671F\u7BC4\u570D\u4E0D\u80FD\u5C0F\u65BC%d\u5929",
      "default-more": "\u8ACB\u9078\u64C7\u5927\u65BC%d\u5929\u7684\u65E5\u671F\u7BC4\u570D",
      "default-less": "\u8ACB\u9078\u64C7\u5C0F\u65BC%d\u5929\u7684\u65E5\u671F\u7BC4\u570D",
      "default-range": "\u8ACB\u9078\u64C7%d\u5929\u5230%d\u5929\u7684\u65E5\u671F\u7BC4\u570D",
      "default-single": "\u8ACB\u9078\u64C7\u4E00\u500B\u65E5\u671F",
      "default-default": "\u8ACB\u9078\u64C7\u4E00\u500B\u65E5\u671F\u7BC4\u570D",
      "time": "\u65E5\u671F",
      "hour": "\u5C0F\u6642",
      "minute": "\u5206\u9418"
    },
    "ja": {
      "selected": "\u9078\u629E\u3057\u307E\u3057\u305F:",
      "day": "\u65E5",
      "days": "\u65E5\u3005",
      "apply": "\u9589\u3058\u308B",
      "week-1": "\u6708",
      "week-2": "\u706B",
      "week-3": "\u6C34",
      "week-4": "\u6728",
      "week-5": "\u91D1",
      "week-6": "\u571F",
      "week-7": "\u65E5",
      "month-name": ["1\u6708", "2\u6708", "3\u6708", "4\u6708", "5\u6708", "6\u6708", "7\u6708", "8\u6708", "9\u6708", "10\u6708", "11\u6708", "12\u6708"],
      "shortcuts": "\u30AF\u30A4\u30C3\u30AF\u9078\u629E",
      "past": "\u904E\u53BB",
      "following": "\u5C06\u6765",
      "previous": "&nbsp;&nbsp;&nbsp;",
      "prev-week": "\u5148\u9031\u3001",
      "prev-month": "\u5148\u6708",
      "prev-year": "\u6628\u5E74",
      "next": "&nbsp;&nbsp;&nbsp;",
      "next-week": "\u6765\u9031",
      "next-month": "\u6765\u6708",
      "next-year": "\u6765\u5E74",
      "less-than": "\u65E5\u4ED8\u306E\u7BC4\u56F2\u306F \uFF05d \u65E5\u4EE5\u4E0A\u306B\u3059\u3079\u304D\u3067\u306F\u3042\u308A\u307E\u305B\u3093",
      "more-than": "\u65E5\u4ED8\u306E\u7BC4\u56F2\u306F \uFF05d \u65E5\u3092\u4E0B\u56DE\u3063\u3066\u306F\u3044\u3051\u307E\u305B\u3093",
      "default-more": "\uFF05d \u65E5\u3088\u308A\u3082\u9577\u3044\u671F\u9593\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
      "default-less": "\uFF05d \u65E5\u672A\u6E80\u306E\u671F\u9593\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
      "default-range": "\uFF05d \u3068\uFF05 d\u65E5\u306E\u9593\u306E\u65E5\u4ED8\u7BC4\u56F2\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
      "default-single": "\u65E5\u4ED8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
      "default-default": "\u65E5\u4ED8\u7BC4\u56F2\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
      "time": "\u6642\u9593",
      "hour": "\u6642\u9593",
      "minute": "\u5206"
    },
    "da": {
      "selected": "Valgt:",
      "day": "Dag",
      "days": "Dage",
      "apply": "Luk",
      "week-1": "ma",
      "week-2": "ti",
      "week-3": "on",
      "week-4": "to",
      "week-5": "fr",
      "week-6": "l\xF6",
      "week-7": "s\xF6",
      "month-name": ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
      "shortcuts": "genveje",
      "custom-values": "Brugerdefinerede v\xE6rdier",
      "past": "Forbi",
      "following": "F\xF8lgende",
      "previous": "Forrige",
      "prev-week": "uge",
      "prev-month": "m\xE5nad",
      "prev-year": "\xE5r",
      "next": "N\xE6ste",
      "next-week": "N\xE6ste uge",
      "next-month": "N\xE6ste m\xE5ned",
      "next-year": "N\xE6ste \xE5r",
      "less-than": "Dato interval b\xF8r ikke v\xE6re med end %d dage",
      "more-than": "Dato interval b\xF8r ikke v\xE6re mindre end %d dage",
      "default-more": "V\xE6lg datointerval l\xE6ngere end %d dage",
      "default-single": "V\xE6lg dato",
      "default-less": "V\xE6lg datointerval mindre end %d dage",
      "default-range": "V\xE6lg datointerval mellem %d og %d dage",
      "default-default": "V\xE6lg datointerval",
      "time": "tid",
      "hour": "time",
      "minute": "minut"
    },
    "fi": // Finnish
    {
      "selected": "Valittu:",
      "day": "P\xE4iv\xE4",
      "days": "P\xE4iv\xE4\xE4",
      "apply": "Sulje",
      "week-1": "ma",
      "week-2": "ti",
      "week-3": "ke",
      "week-4": "to",
      "week-5": "pe",
      "week-6": "la",
      "week-7": "su",
      "week-number": "V",
      "month-name": ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kes\xE4kuu", "hein\xE4kuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"],
      "shortcuts": "Pikavalinnat",
      "custom-values": "Mukautetut Arvot",
      "past": "Menneet",
      "following": "Tulevat",
      "previous": "Edellinen",
      "prev-week": "Viikko",
      "prev-month": "Kuukausi",
      "prev-year": "Vuosi",
      "next": "Seuraava",
      "next-week": "Viikko",
      "next-month": "Kuukausi",
      "next-year": "Vuosi",
      "less-than": "Aikajakson tulisi olla v\xE4hemm\xE4n kuin %d p\xE4iv\xE4\xE4",
      "more-than": "Aikajakson ei tulisi olla v\xE4hemp\xE4\xE4 kuin %d p\xE4iv\xE4\xE4",
      "default-more": "Valitse pidempi aikajakso kuin %d p\xE4iv\xE4\xE4",
      "default-single": "Valitse p\xE4iv\xE4",
      "default-less": "Valitse lyhyempi aikajakso kuin %d p\xE4iv\xE4\xE4",
      "default-range": "Valitse aikajakso %d ja %d p\xE4iv\xE4n v\xE4lilt\xE4",
      "default-default": "Valitse aikajakso",
      "time": "Aika",
      "hour": "Tunti",
      "minute": "Minuutti"
    },
    "cat": // Catala
    {
      "selected": "Seleccionats:",
      "day": "Dia",
      "days": "Dies",
      "apply": "Tanca",
      "week-1": "Dl",
      "week-2": "Dm",
      "week-3": "Dc",
      "week-4": "Dj",
      "week-5": "Dv",
      "week-6": "Ds",
      "week-7": "Dg",
      "week-number": "S",
      "month-name": ["gener", "febrer", "mar\xE7", "abril", "maig", "juny", "juliol", "agost", "setembre", "octubre", "novembre", "desembre"],
      "shortcuts": "Dre\xE7eres",
      "custom-values": "Valors personalitzats",
      "past": "Passat",
      "following": "Futur",
      "previous": "Anterior",
      "prev-week": "Setmana",
      "prev-month": "Mes",
      "prev-year": "Any",
      "next": "Seg\xFCent",
      "next-week": "Setmana",
      "next-month": "Mes",
      "next-year": "Any",
      "less-than": "El per\xEDode no hauria de ser de m\xE9s de %d dies",
      "more-than": "El per\xEDode no hauria de ser de menys de %d dies",
      "default-more": "Perfavor selecciona un per\xEDode m\xE9s gran de %d dies",
      "default-single": "Perfavor selecciona una data",
      "default-less": "Perfavor selecciona un per\xEDode de menys de %d dies",
      "default-range": "Perfavor selecciona un per\xEDode d'entre %d i %d dies",
      "default-default": "Perfavor selecciona un per\xEDode",
      "time": "Temps",
      "hour": "Hora",
      "minute": "Minut"
    }
  };

  $.fn.dateRangePicker = function (opt) {
    if (!opt) opt = {};
    opt = $.extend(true, {
      autoClose: false,
      format: "YYYY-MM-DD",
      separator: " to ",
      language: "auto",
      startOfWeek: "sunday",
      // or monday
      getValue: function () {
        return $(this || _global).val();
      },
      setValue: function (s) {
        if (!$(this || _global).attr("readonly") && !$(this || _global).is(":disabled") && s != $(this || _global).val()) {
          $(this || _global).val(s);
        }
      },
      startDate: false,
      endDate: false,
      time: {
        enabled: false
      },
      minDays: 0,
      maxDays: 0,
      showShortcuts: false,
      shortcuts: {//'prev-days': [1,3,5,7],
        // 'next-days': [3,5,7],
        //'prev' : ['week','month','year'],
        // 'next' : ['week','month','year']
      },
      customShortcuts: [],
      inline: false,
      container: "body",
      alwaysOpen: false,
      singleDate: false,
      lookBehind: false,
      batchMode: false,
      duration: 200,
      stickyMonths: false,
      dayDivAttrs: [],
      dayTdAttrs: [],
      selectForward: false,
      selectBackward: false,
      applyBtnClass: "",
      singleMonth: "auto",
      hoveringTooltip: function (days, startTime, hoveringTime) {
        return days > 1 ? days + " " + translate("days") : "";
      },
      showTopbar: true,
      swapTime: false,
      showWeekNumbers: false,
      getWeekNumber: function (date) //date will be the first day of a week
      {
        return moment(date).format("w");
      },
      customOpenAnimation: null,
      customCloseAnimation: null,
      customArrowPrevSymbol: null,
      customArrowNextSymbol: null
    }, opt);
    opt.start = false;
    opt.end = false;
    opt.startWeek = false; //detect a touch device

    opt.isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints; //if it is a touch device, hide hovering tooltip

    if (opt.isTouchDevice) opt.hoveringTooltip = false; //show one month on mobile devices

    if (opt.singleMonth == "auto") opt.singleMonth = $(window).width() < 480;
    if (opt.singleMonth) opt.stickyMonths = false;
    if (!opt.showTopbar) opt.autoClose = true;
    if (opt.startDate && typeof opt.startDate == "string") opt.startDate = moment(opt.startDate, opt.format).toDate();
    if (opt.endDate && typeof opt.endDate == "string") opt.endDate = moment(opt.endDate, opt.format).toDate();
    var languages = getLanguages();
    var box;
    var initiated = false;
    var self = this || _global;
    var selfDom = $(self).get(0);
    var domChangeTimer;
    $(this || _global).unbind(".datepicker").bind("click.datepicker", function (evt) {
      var isOpen = box.is(":visible");
      if (!isOpen) open(opt.duration);
    }).bind("change.datepicker", function (evt) {
      checkAndSetDefaultValue();
    }).bind("keyup.datepicker", function () {
      try {
        clearTimeout(domChangeTimer);
      } catch (e) {}

      domChangeTimer = setTimeout(function () {
        checkAndSetDefaultValue();
      }, 2000);
    });
    init_datepicker.call(this || _global);

    if (opt.alwaysOpen) {
      open(0);
    } // expose some api


    $(this || _global).data("dateRangePicker", {
      setStart: function (d1) {
        if (typeof d1 == "string") {
          d1 = moment(d1, opt.format).toDate();
        }

        opt.end = false;
        setSingleDate(d1);
        return this || _global;
      },
      setEnd: function (d2, silent) {
        var start = new Date();
        start.setTime(opt.start);

        if (typeof d2 == "string") {
          d2 = moment(d2, opt.format).toDate();
        }

        setDateRange(start, d2, silent);
        return this || _global;
      },
      setDateRange: function (d1, d2, silent) {
        if (typeof d1 == "string" && typeof d2 == "string") {
          d1 = moment(d1, opt.format).toDate();
          d2 = moment(d2, opt.format).toDate();
        }

        setDateRange(d1, d2, silent);
      },
      clear: clearSelection,
      close: closeDatePicker,
      open: open,
      redraw: redrawDatePicker,
      getDatePicker: getDatePicker,
      resetMonthsView: resetMonthsView,
      destroy: function () {
        $(self).unbind(".datepicker");
        $(self).data("dateRangePicker", "");
        $(self).data("date-picker-opened", null);
        box.remove();
        $(window).unbind("resize.datepicker", calcPosition);
        $(document).unbind("click.datepicker", closeDatePicker);
      }
    });
    $(window).bind("resize.datepicker", calcPosition);
    return this || _global;

    function IsOwnDatePickerClicked(evt, selfObj) {
      return selfObj.contains(evt.target) || evt.target == selfObj || selfObj.childNodes != undefined && $.inArray(evt.target, selfObj.childNodes) >= 0;
    }

    function init_datepicker() {
      var self = this || _global;

      if ($(this || _global).data("date-picker-opened")) {
        closeDatePicker();
        return;
      }

      $(this || _global).data("date-picker-opened", true);
      box = createDom().hide();
      box.append("<div class=\"date-range-length-tip\"></div>");
      $(opt.container).append(box);

      if (!opt.inline) {
        calcPosition();
      } else {
        box.addClass("inline-wrapper");
      }

      if (opt.alwaysOpen) {
        box.find(".apply-btn").hide();
      }

      var defaultTime = getDefaultTime();
      resetMonthsView(defaultTime);

      if (opt.time.enabled) {
        if (opt.startDate && opt.endDate || opt.start && opt.end) {
          showTime(moment(opt.start || opt.startDate).toDate(), "time1");
          showTime(moment(opt.end || opt.endDate).toDate(), "time2");
        } else {
          var defaultEndTime = opt.defaultEndTime ? opt.defaultEndTime : defaultTime;
          showTime(defaultTime, "time1");
          showTime(defaultEndTime, "time2");
        }
      } //showSelectedInfo();


      var defaultTopText = "";
      if (opt.singleDate) defaultTopText = translate("default-single");else if (opt.minDays && opt.maxDays) defaultTopText = translate("default-range");else if (opt.minDays) defaultTopText = translate("default-more");else if (opt.maxDays) defaultTopText = translate("default-less");else defaultTopText = translate("default-default");
      box.find(".default-top").html(defaultTopText.replace(/\%d/, opt.minDays).replace(/\%d/, opt.maxDays));

      if (opt.singleMonth) {
        box.addClass("single-month");
      } else {
        box.addClass("two-months");
      }

      setTimeout(function () {
        updateCalendarWidth();
        initiated = true;
      }, 0);
      box.click(function (evt) {
        evt.stopPropagation();
      }); //if user click other place of the webpage, close date range picker window

      $(document).bind("click.datepicker", function (evt) {
        if (!IsOwnDatePickerClicked(evt, self[0])) {
          if (box.is(":visible")) closeDatePicker();
        }
      });
      box.find(".next").click(function () {
        if (!opt.stickyMonths) gotoNextMonth(this || _global);else gotoNextMonth_stickily(this || _global);
      });

      function gotoNextMonth(self) {
        var isMonth2 = $(self).parents("table").hasClass("month2");
        var month = isMonth2 ? opt.month2 : opt.month1;
        month = nextMonth(month);
        if (!opt.singleMonth && !opt.singleDate && !isMonth2 && compare_month(month, opt.month2) >= 0 || isMonthOutOfBounds(month)) return;
        showMonth(month, isMonth2 ? "month2" : "month1");
        showGap();
      }

      function gotoNextMonth_stickily(self) {
        var nextMonth1 = nextMonth(opt.month1);
        var nextMonth2 = nextMonth(opt.month2);
        if (isMonthOutOfBounds(nextMonth2)) return;
        if (!opt.singleDate && compare_month(nextMonth1, nextMonth2) >= 0) return;
        showMonth(nextMonth1, "month1");
        showMonth(nextMonth2, "month2");
        showSelectedDays();
      }

      box.find(".prev").click(function () {
        if (!opt.stickyMonths) gotoPrevMonth(this || _global);else gotoPrevMonth_stickily(this || _global);
      });

      function gotoPrevMonth(self) {
        var isMonth2 = $(self).parents("table").hasClass("month2");
        var month = isMonth2 ? opt.month2 : opt.month1;
        month = prevMonth(month);
        if (isMonth2 && compare_month(month, opt.month1) <= 0 || isMonthOutOfBounds(month)) return;
        showMonth(month, isMonth2 ? "month2" : "month1");
        showGap();
      }

      function gotoPrevMonth_stickily(self) {
        var prevMonth1 = prevMonth(opt.month1);
        var prevMonth2 = prevMonth(opt.month2);
        if (isMonthOutOfBounds(prevMonth1)) return;
        if (!opt.singleDate && compare_month(prevMonth2, prevMonth1) <= 0) return;
        showMonth(prevMonth2, "month2");
        showMonth(prevMonth1, "month1");
        showSelectedDays();
      }

      box.attr("unselectable", "on").css("user-select", "none").bind("selectstart", function (e) {
        e.preventDefault();
        return false;
      });
      box.find(".apply-btn").click(function () {
        closeDatePicker();
        var dateRange = getDateString(new Date(opt.start)) + opt.separator + getDateString(new Date(opt.end));
        $(self).trigger("datepicker-apply", {
          "value": dateRange,
          "date1": new Date(opt.start),
          "date2": new Date(opt.end)
        });
      });
      box.find("[custom]").click(function () {
        var valueName = $(this || _global).attr("custom");
        opt.start = false;
        opt.end = false;
        box.find(".day.checked").removeClass("checked");
        opt.setValue.call(selfDom, valueName);
        checkSelectionValid();
        showSelectedInfo(true);
        showSelectedDays();
        if (opt.autoClose) closeDatePicker();
      });
      box.find("[shortcut]").click(function () {
        var shortcut = $(this || _global).attr("shortcut");
        var end = new Date(),
            start = false;
        var dir;

        if (shortcut.indexOf("day") != -1) {
          var day = parseInt(shortcut.split(",", 2)[1], 10);
          start = new Date(new Date().getTime() + 86400000 * day);
          end = new Date(end.getTime() + 86400000 * (day > 0 ? 1 : -1));
        } else if (shortcut.indexOf("week") != -1) {
          dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;
          var stopDay;
          if (dir == 1) stopDay = opt.startOfWeek == "monday" ? 1 : 0;else stopDay = opt.startOfWeek == "monday" ? 0 : 6;
          end = new Date(end.getTime() - 86400000);

          while (end.getDay() != stopDay) end = new Date(end.getTime() + dir * 86400000);

          start = new Date(end.getTime() + dir * 86400000 * 6);
        } else if (shortcut.indexOf("month") != -1) {
          dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;
          if (dir == 1) start = nextMonth(end);else start = prevMonth(end);
          start.setDate(1);
          end = nextMonth(start);
          end.setDate(1);
          end = new Date(end.getTime() - 86400000);
        } else if (shortcut.indexOf("year") != -1) {
          dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;
          start = new Date();
          start.setFullYear(end.getFullYear() + dir);
          start.setMonth(0);
          start.setDate(1);
          end.setFullYear(end.getFullYear() + dir);
          end.setMonth(11);
          end.setDate(31);
        } else if (shortcut == "custom") {
          var name = $(this || _global).html();

          if (opt.customShortcuts && opt.customShortcuts.length > 0) {
            for (var i = 0; i < opt.customShortcuts.length; i++) {
              var sh = opt.customShortcuts[i];

              if (sh.name == name) {
                var data = []; // try
                // {

                data = sh["dates"].call(); //}catch(e){}

                if (data && data.length == 2) {
                  start = data[0];
                  end = data[1];
                } // if only one date is specified then just move calendars there
                // move calendars to show this date's month and next months


                if (data && data.length == 1) {
                  var movetodate = data[0];
                  showMonth(movetodate, "month1");
                  showMonth(nextMonth(movetodate), "month2");
                  showGap();
                }

                break;
              }
            }
          }
        }

        if (start && end) {
          setDateRange(start, end);
          checkSelectionValid();
        }
      });
      box.find(".time1 input[type=range]").bind("change touchmove", function (e) {
        var target = e.target,
            hour = target.name == "hour" ? $(target).val().replace(/^(\d{1})$/, "0$1") : undefined,
            min = target.name == "minute" ? $(target).val().replace(/^(\d{1})$/, "0$1") : undefined;
        setTime("time1", hour, min);
      });
      box.find(".time2 input[type=range]").bind("change touchmove", function (e) {
        var target = e.target,
            hour = target.name == "hour" ? $(target).val().replace(/^(\d{1})$/, "0$1") : undefined,
            min = target.name == "minute" ? $(target).val().replace(/^(\d{1})$/, "0$1") : undefined;
        setTime("time2", hour, min);
      });
    }

    function calcPosition() {
      if (!opt.inline) {
        var offset = $(self).offset();

        if ($(opt.container).css("position") == "relative") {
          var containerOffset = $(opt.container).offset();
          box.css({
            top: offset.top - containerOffset.top + $(self).outerHeight() + 4,
            left: offset.left - containerOffset.left
          });
        } else {
          if (offset.left < 460) //left to right
            {
              box.css({
                top: offset.top + $(self).outerHeight() + parseInt($("body").css("border-top") || 0, 10),
                left: offset.left
              });
            } else {
            box.css({
              top: offset.top + $(self).outerHeight() + parseInt($("body").css("border-top") || 0, 10),
              left: offset.left + $(self).outerWidth() - box.outerWidth()
            });
          }
        }
      }
    } // Return the date picker wrapper element


    function getDatePicker() {
      return box;
    }

    function open(animationTime) {
      calcPosition();
      redrawDatePicker();
      checkAndSetDefaultValue();

      if (opt.customOpenAnimation) {
        opt.customOpenAnimation.call(box.get(0), function () {
          $(self).trigger("datepicker-opened", {
            relatedTarget: box
          });
        });
      } else {
        box.addClass("show");
        setTimeout(function () {
          $(self).trigger("datepicker-opened", {
            relatedTarget: box
          });
        }, 10); // box.slideDown(animationTime, function() {
        //   $(self).trigger('datepicker-opened', {
        //     relatedTarget: box
        //   });
        // });
      }

      $(self).trigger("datepicker-open", {
        relatedTarget: box
      });
      showGap();
      updateCalendarWidth();
    }

    function checkAndSetDefaultValue() {
      var __default_string = opt.getValue.call(selfDom);

      var defaults = __default_string ? __default_string.split(opt.separator) : "";

      if (defaults && (defaults.length == 1 && opt.singleDate || defaults.length >= 2)) {
        var ___format = opt.format;

        if (___format.match(/Do/)) {
          ___format = ___format.replace(/Do/, "D");
          defaults[0] = defaults[0].replace(/(\d+)(th|nd|st)/, "$1");

          if (defaults.length >= 2) {
            defaults[1] = defaults[1].replace(/(\d+)(th|nd|st)/, "$1");
          }
        } // set initiated  to avoid triggerring datepicker-change event


        initiated = false;

        if (defaults.length >= 2) {
          setDateRange(getValidValue(defaults[0], ___format, moment.locale(opt.language)), getValidValue(defaults[1], ___format, moment.locale(opt.language)));
        } else if (defaults.length == 1 && opt.singleDate) {
          setSingleDate(getValidValue(defaults[0], ___format, moment.locale(opt.language)));
        }

        initiated = true;
      }
    }

    function getValidValue(date, format, locale) {
      if (moment(date, format, locale).isValid()) {
        return moment(date, format, locale).toDate();
      } else {
        return moment().toDate();
      }
    }

    function updateCalendarWidth() {
      var gapMargin = box.find(".gap").css("margin-left");
      if (gapMargin) gapMargin = parseInt(gapMargin);
      var w1 = box.find(".month1").width();
      var w2 = box.find(".gap").width() + (gapMargin ? gapMargin * 2 : 0);
      var w3 = box.find(".month2").width(); // box.find('.month-wrapper').width(w1 + w2 + w3);

      box.find(".month-wrapper").width(w1 + w3);
    }

    function renderTime(name, date) {
      box.find("." + name + " input[type=range].hour-range").val(moment(date).hours());
      box.find("." + name + " input[type=range].minute-range").val(moment(date).minutes());
      setTime(name, moment(date).format("HH"), moment(date).format("mm"));
    }

    function changeTime(name, date) {
      opt[name] = parseInt(moment(parseInt(date)).startOf("day").add(moment(opt[name + "Time"]).format("HH"), "h").add(moment(opt[name + "Time"]).format("mm"), "m").valueOf());
    }

    function swapTime() {
      renderTime("time1", opt.start);
      renderTime("time2", opt.end);
    }

    function setTime(name, hour, minute) {
      hour && box.find("." + name + " .hour-val").text(hour);
      minute && box.find("." + name + " .minute-val").text(minute);

      switch (name) {
        case "time1":
          if (opt.start) {
            setRange("start", moment(opt.start));
          }

          setRange("startTime", moment(opt.startTime || moment().valueOf()));
          break;

        case "time2":
          if (opt.end) {
            setRange("end", moment(opt.end));
          }

          setRange("endTime", moment(opt.endTime || moment().valueOf()));
          break;
      }

      function setRange(name, timePoint) {
        var h = timePoint.format("HH"),
            m = timePoint.format("mm");
        opt[name] = timePoint.startOf("day").add(hour || h, "h").add(minute || m, "m").valueOf();
      }

      checkSelectionValid();
      showSelectedInfo();
      showSelectedDays();
    }

    function clearSelection() {
      opt.start = false;
      opt.end = false;
      box.find(".day.checked").removeClass("checked");
      box.find(".day.last-date-selected").removeClass("last-date-selected");
      box.find(".day.first-date-selected").removeClass("first-date-selected");
      opt.setValue.call(selfDom, "");
      checkSelectionValid();
      showSelectedInfo();
      showSelectedDays();
    }

    function handleStart(time) {
      var r = time;

      if (opt.batchMode === "week-range") {
        if (opt.startOfWeek === "monday") {
          r = moment(parseInt(time)).startOf("isoweek").valueOf();
        } else {
          r = moment(parseInt(time)).startOf("week").valueOf();
        }
      } else if (opt.batchMode === "month-range") {
        r = moment(parseInt(time)).startOf("month").valueOf();
      }

      return r;
    }

    function handleEnd(time) {
      var r = time;

      if (opt.batchMode === "week-range") {
        if (opt.startOfWeek === "monday") {
          r = moment(parseInt(time)).endOf("isoweek").valueOf();
        } else {
          r = moment(parseInt(time)).endOf("week").valueOf();
        }
      } else if (opt.batchMode === "month-range") {
        r = moment(parseInt(time)).endOf("month").valueOf();
      }

      return r;
    }

    function dayClicked(day) {
      if (day.hasClass("invalid")) return;
      var time = day.attr("time");
      day.addClass("checked");

      if (opt.singleDate) {
        opt.start = time;
        opt.end = false;
      } else if (opt.batchMode === "week") {
        if (opt.startOfWeek === "monday") {
          opt.start = moment(parseInt(time)).startOf("isoweek").valueOf();
          opt.end = moment(parseInt(time)).endOf("isoweek").valueOf();
        } else {
          opt.end = moment(parseInt(time)).endOf("week").valueOf();
          opt.start = moment(parseInt(time)).startOf("week").valueOf();
        }
      } else if (opt.batchMode === "workweek") {
        opt.start = moment(parseInt(time)).day(1).valueOf();
        opt.end = moment(parseInt(time)).day(5).valueOf();
      } else if (opt.batchMode === "weekend") {
        opt.start = moment(parseInt(time)).day(6).valueOf();
        opt.end = moment(parseInt(time)).day(7).valueOf();
      } else if (opt.batchMode === "month") {
        opt.start = moment(parseInt(time)).startOf("month").valueOf();
        opt.end = moment(parseInt(time)).endOf("month").valueOf();
      } else if (opt.start && opt.end || !opt.start && !opt.end) {
        opt.start = handleStart(time);
        opt.end = false;
      } else if (opt.start) {
        opt.end = handleEnd(time);

        if (opt.time.enabled) {
          changeTime("end", opt.end);
        }
      } //Update time in case it is enabled and timestamps are available


      if (opt.time.enabled) {
        if (opt.start) {
          changeTime("start", opt.start);
        }

        if (opt.end) {
          changeTime("end", opt.end);
        }
      } //In case the start is after the end, swap the timestamps


      if (!opt.singleDate && opt.start && opt.end && opt.start > opt.end) {
        var tmp = opt.end;
        opt.end = handleEnd(opt.start);
        opt.start = handleStart(tmp);

        if (opt.time.enabled && opt.swapTime) {
          swapTime();
        }
      }

      opt.start = parseInt(opt.start);
      opt.end = parseInt(opt.end);
      clearHovering();

      if (opt.start && !opt.end) {
        $(self).trigger("datepicker-first-date-selected", {
          "date1": new Date(opt.start)
        });
        dayHovering(day);
      }

      updateSelectableRange(time);
      checkSelectionValid();
      showSelectedInfo();
      showSelectedDays();
      autoclose();
    }

    function weekNumberClicked(weekNumberDom) {
      var thisTime = parseInt(weekNumberDom.attr("data-start-time"), 10);
      var date1, date2;

      if (!opt.startWeek) {
        opt.startWeek = thisTime;
        weekNumberDom.addClass("week-number-selected");
        date1 = new Date(thisTime);
        opt.start = moment(date1).day(opt.startOfWeek == "monday" ? 1 : 0).valueOf();
        opt.end = moment(date1).day(opt.startOfWeek == "monday" ? 7 : 6).valueOf();
      } else {
        box.find(".week-number-selected").removeClass("week-number-selected");
        date1 = new Date(thisTime < opt.startWeek ? thisTime : opt.startWeek);
        date2 = new Date(thisTime < opt.startWeek ? opt.startWeek : thisTime);
        opt.startWeek = false;
        opt.start = moment(date1).day(opt.startOfWeek == "monday" ? 1 : 0).valueOf();
        opt.end = moment(date2).day(opt.startOfWeek == "monday" ? 7 : 6).valueOf();
      }

      updateSelectableRange();
      checkSelectionValid();
      showSelectedInfo();
      showSelectedDays();
      autoclose();
    }

    function isValidTime(time) {
      time = parseInt(time, 10);
      if (opt.startDate && compare_day(time, opt.startDate) < 0) return false;
      if (opt.endDate && compare_day(time, opt.endDate) > 0) return false;

      if (opt.start && !opt.end && !opt.singleDate) {
        //check maxDays and minDays setting
        if (opt.maxDays > 0 && countDays(time, opt.start) > opt.maxDays) return false;
        if (opt.minDays > 0 && countDays(time, opt.start) < opt.minDays) return false; //check selectForward and selectBackward

        if (opt.selectForward && time < opt.start) return false;
        if (opt.selectBackward && time > opt.start) return false; //check disabled days

        if (opt.beforeShowDay && typeof opt.beforeShowDay == "function") {
          var valid = true;
          var timeTmp = time;

          while (countDays(timeTmp, opt.start) > 1) {
            var arr = opt.beforeShowDay(new Date(timeTmp));

            if (!arr[0]) {
              valid = false;
              break;
            }

            if (Math.abs(timeTmp - opt.start) < 86400000) break;
            if (timeTmp > opt.start) timeTmp -= 86400000;
            if (timeTmp < opt.start) timeTmp += 86400000;
          }

          if (!valid) return false;
        }
      }

      return true;
    }

    function updateSelectableRange() {
      box.find(".day.invalid.tmp").removeClass("tmp invalid").addClass("valid");

      if (opt.start && !opt.end) {
        box.find(".day.toMonth.valid").each(function () {
          var time = parseInt($(this || _global).attr("time"), 10);
          if (!isValidTime(time)) $(this || _global).addClass("invalid tmp").removeClass("valid");else $(this || _global).addClass("valid tmp").removeClass("invalid");
        });
      }

      return true;
    }

    function dayHovering(day) {
      var hoverTime = parseInt(day.attr("time"));
      var tooltip = "";

      if (day.hasClass("has-tooltip") && day.attr("data-tooltip")) {
        tooltip = "<span style=\"white-space:nowrap\">" + day.attr("data-tooltip") + "</span>";
      } else if (!day.hasClass("invalid")) {
        if (opt.singleDate) {
          box.find(".day.hovering").removeClass("hovering");
          day.addClass("hovering");
        } else {
          box.find(".day").each(function () {
            var time = parseInt($(this || _global).attr("time")),
                start = opt.start,
                end = opt.end;

            if (time == hoverTime) {
              $(this || _global).addClass("hovering");
            } else {
              $(this || _global).removeClass("hovering");
            }

            if (opt.start && !opt.end && (opt.start < time && hoverTime >= time || opt.start > time && hoverTime <= time)) {
              $(this || _global).addClass("hovering");
            } else {
              $(this || _global).removeClass("hovering");
            }
          });

          if (opt.start && !opt.end) {
            var days = countDays(hoverTime, opt.start);

            if (opt.hoveringTooltip) {
              if (typeof opt.hoveringTooltip == "function") {
                tooltip = opt.hoveringTooltip(days, opt.start, hoverTime);
              } else if (opt.hoveringTooltip === true && days > 1) {
                tooltip = days + " " + translate("days");
              }
            }
          }
        }
      }

      if (tooltip) {
        var posDay = day.offset();
        var posBox = box.offset();

        var _left = posDay.left - posBox.left;

        var _top = posDay.top - posBox.top;

        _left += day.width() / 2;
        var $tip = box.find(".date-range-length-tip");
        var w = $tip.css({
          "visibility": "hidden",
          "display": "none"
        }).html(tooltip).width();
        var h = $tip.height();
        _left -= w / 2;
        _top -= h;
        setTimeout(function () {
          $tip.css({
            left: _left,
            top: _top,
            display: "block",
            "visibility": "visible"
          });
        }, 10);
      } else {
        box.find(".date-range-length-tip").hide();
      }
    }

    function clearHovering() {
      box.find(".day.hovering").removeClass("hovering");
      box.find(".date-range-length-tip").hide();
    }

    function autoclose() {
      if (opt.singleDate === true) {
        if (initiated && opt.start) {
          if (opt.autoClose) closeDatePicker();
        }
      } else {
        if (initiated && opt.start && opt.end) {
          if (opt.autoClose) closeDatePicker();
        }
      }
    }

    function checkSelectionValid() {
      var days = Math.ceil((opt.end - opt.start) / 86400000) + 1;

      if (opt.singleDate) {
        // Validate if only start is there
        if (opt.start && !opt.end) box.find(".drp_top-bar").removeClass("error").addClass("normal");else box.find(".drp_top-bar").removeClass("error").removeClass("normal");
      } else if (opt.maxDays && days > opt.maxDays) {
        opt.start = false;
        opt.end = false;
        box.find(".day").removeClass("checked");
        box.find(".drp_top-bar").removeClass("normal").addClass("error").find(".error-top").html(translate("less-than").replace("%d", opt.maxDays));
      } else if (opt.minDays && days < opt.minDays) {
        opt.start = false;
        opt.end = false;
        box.find(".day").removeClass("checked");
        box.find(".drp_top-bar").removeClass("normal").addClass("error").find(".error-top").html(translate("more-than").replace("%d", opt.minDays));
      } else {
        if (opt.start || opt.end) box.find(".drp_top-bar").removeClass("error").addClass("normal");else box.find(".drp_top-bar").removeClass("error").removeClass("normal");
      }

      if (opt.singleDate && opt.start && !opt.end || !opt.singleDate && opt.start && opt.end) {
        box.find(".apply-btn").removeClass("disabled");
      } else {
        box.find(".apply-btn").addClass("disabled");
      }

      if (opt.batchMode) {
        if (opt.start && opt.startDate && compare_day(opt.start, opt.startDate) < 0 || opt.end && opt.endDate && compare_day(opt.end, opt.endDate) > 0) {
          opt.start = false;
          opt.end = false;
          box.find(".day").removeClass("checked");
        }
      }
    }

    function showSelectedInfo(forceValid, silent) {
      box.find(".start-day").html("...");
      box.find(".end-day").html("...");
      box.find(".selected-days").hide();

      if (opt.start) {
        box.find(".start-day").html(getDateString(new Date(parseInt(opt.start))));
      }

      if (opt.end) {
        box.find(".end-day").html(getDateString(new Date(parseInt(opt.end))));
      }

      var dateRange;

      if (opt.start && opt.singleDate) {
        box.find(".apply-btn").removeClass("disabled");
        dateRange = getDateString(new Date(opt.start));
        opt.setValue.call(selfDom, dateRange, getDateString(new Date(opt.start)), getDateString(new Date(opt.end)));

        if (initiated && !silent) {
          $(self).trigger("datepicker-change", {
            "value": dateRange,
            "date1": new Date(opt.start)
          });
        }
      } else if (opt.start && opt.end) {
        box.find(".selected-days").show().find(".selected-days-num").html(countDays(opt.end, opt.start));
        box.find(".apply-btn").removeClass("disabled");
        dateRange = getDateString(new Date(opt.start)) + opt.separator + getDateString(new Date(opt.end));
        opt.setValue.call(selfDom, dateRange, getDateString(new Date(opt.start)), getDateString(new Date(opt.end)));

        if (initiated && !silent) {
          $(self).trigger("datepicker-change", {
            "value": dateRange,
            "date1": new Date(opt.start),
            "date2": new Date(opt.end)
          });
        }
      } else if (forceValid) {
        box.find(".apply-btn").removeClass("disabled");
      } else {
        box.find(".apply-btn").addClass("disabled");
      }
    }

    function countDays(start, end) {
      return Math.abs(daysFrom1970(start) - daysFrom1970(end)) + 1;
    }

    function setDateRange(date1, date2, silent) {
      if (date1.getTime() > date2.getTime()) {
        var tmp = date2;
        date2 = date1;
        date1 = tmp;
        tmp = null;
      }

      var valid = true;
      if (opt.startDate && compare_day(date1, opt.startDate) < 0) valid = false;
      if (opt.endDate && compare_day(date2, opt.endDate) > 0) valid = false;

      if (!valid) {
        showMonth(opt.startDate, "month1");
        showMonth(nextMonth(opt.startDate), "month2");
        showGap();
        return;
      }

      opt.start = date1.getTime();
      opt.end = date2.getTime();

      if (opt.time.enabled) {
        renderTime("time1", date1);
        renderTime("time2", date2);
      }

      if (opt.stickyMonths || compare_day(date1, date2) > 0 && compare_month(date1, date2) === 0) {
        if (opt.lookBehind) {
          date1 = prevMonth(date2);
        } else {
          date2 = nextMonth(date1);
        }
      }

      if (opt.stickyMonths && opt.endDate !== false && compare_month(date2, opt.endDate) > 0) {
        date1 = prevMonth(date1);
        date2 = prevMonth(date2);
      }

      if (!opt.stickyMonths) {
        if (compare_month(date1, date2) === 0) {
          if (opt.lookBehind) {
            date1 = prevMonth(date2);
          } else {
            date2 = nextMonth(date1);
          }
        }
      }

      showMonth(date1, "month1");
      showMonth(date2, "month2");
      showGap();
      checkSelectionValid();
      showSelectedInfo(false, silent);
      autoclose();
    }

    function setSingleDate(date1) {
      var valid = true;
      if (opt.startDate && compare_day(date1, opt.startDate) < 0) valid = false;
      if (opt.endDate && compare_day(date1, opt.endDate) > 0) valid = false;

      if (!valid) {
        showMonth(opt.startDate, "month1");
        return;
      }

      opt.start = date1.getTime();

      if (opt.time.enabled) {
        renderTime("time1", date1);
      }

      showMonth(date1, "month1");

      if (opt.singleMonth !== true) {
        var date2 = nextMonth(date1);
        showMonth(date2, "month2");
      }

      showGap();
      showSelectedInfo();
      autoclose();
    }

    function showSelectedDays() {
      if (!opt.start && !opt.end) return;
      box.find(".day").each(function () {
        var time = parseInt($(this || _global).attr("time")),
            start = opt.start,
            end = opt.end;

        if (opt.time.enabled) {
          time = moment(time).startOf("day").valueOf();
          start = moment(start || moment().valueOf()).startOf("day").valueOf();
          end = moment(end || moment().valueOf()).startOf("day").valueOf();
        }

        if (opt.start && opt.end && end >= time && start <= time || opt.start && !opt.end && moment(start).format("YYYY-MM-DD") == moment(time).format("YYYY-MM-DD")) {
          $(this || _global).addClass("checked");
        } else {
          $(this || _global).removeClass("checked");
        } //add first-date-selected class name to the first date selected


        if (opt.start && moment(start).format("YYYY-MM-DD") == moment(time).format("YYYY-MM-DD")) {
          $(this || _global).addClass("first-date-selected");
        } else {
          $(this || _global).removeClass("first-date-selected");
        } //add last-date-selected


        if (opt.end && moment(end).format("YYYY-MM-DD") == moment(time).format("YYYY-MM-DD")) {
          $(this || _global).addClass("last-date-selected");
        } else {
          $(this || _global).removeClass("last-date-selected");
        }
      });
      box.find(".week-number").each(function () {
        if ($(this || _global).attr("data-start-time") == opt.startWeek) {
          $(this || _global).addClass("week-number-selected");
        }
      });
    }

    function showMonth(date, month) {
      date = moment(date).toDate();
      var monthName = nameMonth(date.getMonth());
      box.find("." + month + " .month-name").html(monthName + " " + date.getFullYear());
      box.find("." + month + " tbody").html(createMonthHTML(date));
      opt[month] = date;
      updateSelectableRange();
      bindDayEvents();
    }

    function bindDayEvents() {
      box.find(".day").unbind("click").click(function (evt) {
        dayClicked($(this || _global));
      });
      box.find(".day").unbind("mouseenter").mouseenter(function (evt) {
        dayHovering($(this || _global));
      });
      box.find(".day").unbind("mouseleave").mouseleave(function (evt) {
        box.find(".date-range-length-tip").hide();

        if (opt.singleDate) {
          clearHovering();
        }
      });
      box.find(".week-number").unbind("click").click(function (evt) {
        weekNumberClicked($(this || _global));
      });
    }

    function showTime(date, name) {
      box.find("." + name).append(getTimeHTML());
      renderTime(name, date);
    }

    function nameMonth(m) {
      return translate("month-name")[m];
    }

    function getDateString(d) {
      return moment(d).format(opt.format);
    }

    function showGap() {
      showSelectedDays();
      var m1 = parseInt(moment(opt.month1).format("YYYYMM"));
      var m2 = parseInt(moment(opt.month2).format("YYYYMM"));
      var p = Math.abs(m1 - m2);
      var shouldShow = p > 1 && p != 89;

      if (shouldShow) {
        box.addClass("has-gap").removeClass("no-gap").find(".gap").css("visibility", "visible");
      } else {
        box.removeClass("has-gap").addClass("no-gap").find(".gap").css("visibility", "hidden");
      }

      var h1 = box.find("table.month1").height();
      var h2 = box.find("table.month2").height();
      box.find(".gap").height(Math.max(h1, h2) + 10);
    }

    function closeDatePicker() {
      if (opt.alwaysOpen) return;

      var afterAnim = function () {
        $(self).data("date-picker-opened", false);
        $(self).trigger("datepicker-closed", {
          relatedTarget: box
        });
      };

      if (opt.customCloseAnimation) {
        opt.customCloseAnimation.call(box.get(0), afterAnim);
      } else {
        $(box).removeClass("show"); // $(box).slideUp(opt.duration, afterAnim);
      }

      $(self).trigger("datepicker-close", {
        relatedTarget: box
      });
    }

    function redrawDatePicker() {
      showMonth(opt.month1, "month1");
      showMonth(opt.month2, "month2");
    }

    function compare_month(m1, m2) {
      var p = parseInt(moment(m1).format("YYYYMM")) - parseInt(moment(m2).format("YYYYMM"));
      if (p > 0) return 1;
      if (p === 0) return 0;
      return -1;
    }

    function compare_day(m1, m2) {
      var p = parseInt(moment(m1).format("YYYYMMDD")) - parseInt(moment(m2).format("YYYYMMDD"));
      if (p > 0) return 1;
      if (p === 0) return 0;
      return -1;
    }

    function nextMonth(month) {
      return moment(month).add(1, "months").toDate();
    }

    function prevMonth(month) {
      return moment(month).add(-1, "months").toDate();
    }

    function getTimeHTML() {
      return "<div>" + "<span>" + translate("Time") + ": <span class=\"hour-val\">00</span>:<span class=\"minute-val\">00</span></span>" + "</div>" + "<div class=\"hour\">" + "<label>" + translate("Hour") + ": <input type=\"range\" class=\"hour-range\" name=\"hour\" min=\"0\" max=\"23\"></label>" + "</div>" + "<div class=\"minute\">" + "<label>" + translate("Minute") + ": <input type=\"range\" class=\"minute-range\" name=\"minute\" min=\"0\" max=\"59\"></label>" + "</div>";
    }

    function createDom() {
      var html = "<div class=\"ma-date-range-picker-wrapper date-picker-wrapper";
      if (opt.extraClass) html += " " + opt.extraClass + " ";
      if (opt.singleDate) html += " single-date ";
      if (!opt.showShortcuts) html += " no-shortcuts ";
      if (!opt.showTopbar) html += " no-topbar ";
      if (opt.customTopBar) html += " custom-topbar ";
      html += "\">";

      if (opt.showTopbar) {
        html += "<div class=\"drp_top-bar\">";

        if (opt.customTopBar) {
          if (typeof opt.customTopBar == "function") opt.customTopBar = opt.customTopBar();
          html += "<div class=\"custom-top\">" + opt.customTopBar + "</div>";
        } else {
          html += "<div class=\"normal-top\">" + "<span style=\"color:#333\">" + translate("selected") + " </span> <b class=\"start-day\">...</b>";

          if (!opt.singleDate) {
            html += " <span class=\"separator-day\">" + opt.separator + "</span> <b class=\"end-day\">...</b> <i class=\"selected-days\">(<span class=\"selected-days-num\">3</span> " + translate("days") + ")</i>";
          }

          html += "</div>";
          html += "<div class=\"error-top\">error</div>" + "<div class=\"default-top\">default</div>";
        }

        html += "<input type=\"button\" class=\"apply-btn disabled" + getApplyBtnClass() + "\" value=\"" + translate("apply") + "\" />";
        html += "</div>";
      }

      var _colspan = opt.showWeekNumbers ? 6 : 5;

      var arrowPrev = "&lt;";
      if (opt.customArrowPrevSymbol) arrowPrev = opt.customArrowPrevSymbol;
      var arrowNext = "&gt;";
      if (opt.customArrowNextSymbol) arrowNext = opt.customArrowNextSymbol;
      html += "<div class=\"month-wrapper\">" + "   <table class=\"month1\" cellspacing=\"0\" border=\"0\" cellpadding=\"0\">" + "       <thead>" + "           <tr class=\"caption\">" + "               <th style=\"width:27px;\">" + "                   <span class=\"prev\">" + arrowPrev + "                   </span>" + "               </th>" + "               <th colspan=\"" + _colspan + "\" class=\"month-name\">" + "               </th>" + "               <th style=\"width:27px;\">" + (opt.singleDate || !opt.stickyMonths ? "<span class=\"next\">" + arrowNext + "</span>" : "") + "               </th>" + "           </tr>" + "           <tr class=\"week-name\">" + getWeekHead() + "       </thead>" + "       <tbody></tbody>" + "   </table>";

      if (hasMonth2()) {
        html += "<div class=\"gap\">" + getGapHTML() + "</div>" + "<table class=\"month2\" cellspacing=\"0\" border=\"0\" cellpadding=\"0\">" + "   <thead>" + "   <tr class=\"caption\">" + "       <th style=\"width:27px;\">" + (!opt.stickyMonths ? "<span class=\"prev\">" + arrowPrev + "</span>" : "") + "       </th>" + "       <th colspan=\"" + _colspan + "\" class=\"month-name\">" + "       </th>" + "       <th style=\"width:27px;\">" + "           <span class=\"next\">" + arrowNext + "</span>" + "       </th>" + "   </tr>" + "   <tr class=\"week-name\">" + getWeekHead() + "   </thead>" + "   <tbody></tbody>" + "</table>";
      } //+'</div>'


      html += "<div style=\"clear:both;height:0;font-size:0;\"></div>" + "<div class=\"time\">" + "<div class=\"time1\"></div>";

      if (!opt.singleDate) {
        html += "<div class=\"time2\"></div>";
      }

      html += "</div>" + "<div style=\"clear:both;height:0;font-size:0;\"></div>" + "</div>";
      html += "<div class=\"footer\">";

      if (opt.showShortcuts) {
        html += "<div class=\"shortcuts\"><b>" + translate("shortcuts") + "</b>";
        var data = opt.shortcuts;

        if (data) {
          var name;

          if (data["prev-days"] && data["prev-days"].length > 0) {
            html += "&nbsp;<span class=\"prev-days\">" + translate("past");

            for (var i = 0; i < data["prev-days"].length; i++) {
              name = data["prev-days"][i];
              name += data["prev-days"][i] > 1 ? translate("days") : translate("day");
              html += " <a href=\"javascript:;\" shortcut=\"day,-" + data["prev-days"][i] + "\">" + name + "</a>";
            }

            html += "</span>";
          }

          if (data["next-days"] && data["next-days"].length > 0) {
            html += "&nbsp;<span class=\"next-days\">" + translate("following");

            for (var i = 0; i < data["next-days"].length; i++) {
              name = data["next-days"][i];
              name += data["next-days"][i] > 1 ? translate("days") : translate("day");
              html += " <a href=\"javascript:;\" shortcut=\"day," + data["next-days"][i] + "\">" + name + "</a>";
            }

            html += "</span>";
          }

          if (data.prev && data.prev.length > 0) {
            html += "&nbsp;<span class=\"prev-buttons\">" + translate("previous");

            for (var i = 0; i < data.prev.length; i++) {
              name = translate("prev-" + data.prev[i]);
              html += " <a href=\"javascript:;\" shortcut=\"prev," + data.prev[i] + "\">" + name + "</a>";
            }

            html += "</span>";
          }

          if (data.next && data.next.length > 0) {
            html += "&nbsp;<span class=\"next-buttons\">" + translate("next");

            for (var i = 0; i < data.next.length; i++) {
              name = translate("next-" + data.next[i]);
              html += " <a href=\"javascript:;\" shortcut=\"next," + data.next[i] + "\">" + name + "</a>";
            }

            html += "</span>";
          }
        }

        if (opt.customShortcuts) {
          for (var i = 0; i < opt.customShortcuts.length; i++) {
            var sh = opt.customShortcuts[i];
            html += "&nbsp;<span class=\"custom-shortcut\"><a href=\"javascript:;\" shortcut=\"custom\">" + sh.name + "</a></span>";
          }
        }

        html += "</div>";
      } // Add Custom Values Dom


      if (opt.showCustomValues) {
        html += "<div class=\"customValues\"><b>" + (opt.customValueLabel || translate("custom-values")) + "</b>";

        if (opt.customValues) {
          for (var i = 0; i < opt.customValues.length; i++) {
            var val = opt.customValues[i];
            html += "&nbsp;<span class=\"custom-value\"><a href=\"javascript:;\" custom=\"" + val.value + "\">" + val.name + "</a></span>";
          }
        }
      }

      html += "</div></div>";
      return $(html);
    }

    function getApplyBtnClass() {
      var klass = "";

      if (opt.autoClose === true) {
        klass += " hide";
      }

      if (opt.applyBtnClass !== "") {
        klass += " " + opt.applyBtnClass;
      }

      return klass;
    }

    function getWeekHead() {
      var prepend = opt.showWeekNumbers ? "<th>" + translate("week-number") + "</th>" : "";

      if (opt.startOfWeek == "monday") {
        return prepend + "<th>" + translate("week-1") + "</th>" + "<th>" + translate("week-2") + "</th>" + "<th>" + translate("week-3") + "</th>" + "<th>" + translate("week-4") + "</th>" + "<th>" + translate("week-5") + "</th>" + "<th>" + translate("week-6") + "</th>" + "<th>" + translate("week-7") + "</th>";
      } else {
        return prepend + "<th>" + translate("week-7") + "</th>" + "<th>" + translate("week-1") + "</th>" + "<th>" + translate("week-2") + "</th>" + "<th>" + translate("week-3") + "</th>" + "<th>" + translate("week-4") + "</th>" + "<th>" + translate("week-5") + "</th>" + "<th>" + translate("week-6") + "</th>";
      }
    }

    function isMonthOutOfBounds(month) {
      month = moment(month);

      if (opt.startDate && month.endOf("month").isBefore(opt.startDate)) {
        return true;
      }

      if (opt.endDate && month.startOf("month").isAfter(opt.endDate)) {
        return true;
      }

      return false;
    }

    function getGapHTML() {
      var html = ["<div class=\"gap-top-mask\"></div><div class=\"gap-bottom-mask\"></div><div class=\"gap-lines\">"];

      for (var i = 0; i < 20; i++) {
        html.push("<div class=\"gap-line\">" + "<div class=\"gap-1\"></div>" + "<div class=\"gap-2\"></div>" + "<div class=\"gap-3\"></div>" + "</div>");
      }

      html.push("</div>");
      return html.join("");
    }

    function hasMonth2() {
      return !opt.singleMonth;
    }

    function attributesCallbacks(initialObject, callbacksArray, today) {
      var resultObject = $.extend(true, {}, initialObject);
      $.each(callbacksArray, function (cbAttrIndex, cbAttr) {
        var addAttributes = cbAttr(today);

        for (var attr in addAttributes) {
          if (resultObject.hasOwnProperty(attr)) {
            resultObject[attr] += addAttributes[attr];
          } else {
            resultObject[attr] = addAttributes[attr];
          }
        }
      });
      var attrString = "";

      for (var attr in resultObject) {
        if (resultObject.hasOwnProperty(attr)) {
          attrString += attr + "=\"" + resultObject[attr] + "\" ";
        }
      }

      return attrString;
    }

    function daysFrom1970(t) {
      return Math.floor(toLocalTimestamp(t) / 86400000);
    }

    function toLocalTimestamp(t) {
      if (moment.isMoment(t)) t = t.toDate().getTime();
      if (typeof t == "object" && t.getTime) t = t.getTime();
      if (typeof t == "string" && !t.match(/\d{13}/)) t = moment(t, opt.format).toDate().getTime();
      t = parseInt(t, 10) - new Date().getTimezoneOffset() * 60 * 1000;
      return t;
    }

    function createMonthHTML(d) {
      var days = [];
      d.setDate(1);
      var lastMonth = new Date(d.getTime() - 86400000);
      var now = new Date();
      var dayOfWeek = d.getDay();

      if (dayOfWeek === 0 && opt.startOfWeek === "monday") {
        // add one week
        dayOfWeek = 7;
      }

      var today, valid;

      if (dayOfWeek > 0) {
        for (var i = dayOfWeek; i > 0; i--) {
          var day = new Date(d.getTime() - 86400000 * i);
          valid = isValidTime(day.getTime());
          if (opt.startDate && compare_day(day, opt.startDate) < 0) valid = false;
          if (opt.endDate && compare_day(day, opt.endDate) > 0) valid = false;
          days.push({
            date: day,
            type: "lastMonth",
            day: day.getDate(),
            time: day.getTime(),
            valid: valid
          });
        }
      }

      var toMonth = d.getMonth();

      for (var i = 0; i < 40; i++) {
        today = moment(d).add(i, "days").toDate();
        valid = isValidTime(today.getTime());
        if (opt.startDate && compare_day(today, opt.startDate) < 0) valid = false;
        if (opt.endDate && compare_day(today, opt.endDate) > 0) valid = false;
        days.push({
          date: today,
          type: today.getMonth() == toMonth ? "toMonth" : "nextMonth",
          day: today.getDate(),
          time: today.getTime(),
          valid: valid
        });
      }

      var html = [];

      for (var week = 0; week < 6; week++) {
        if (days[week * 7].type == "nextMonth") break;
        html.push("<tr>");

        for (var day = 0; day < 7; day++) {
          var _day = opt.startOfWeek == "monday" ? day + 1 : day;

          today = days[week * 7 + _day];
          var highlightToday = moment(today.time).format("L") == moment(now).format("L");
          today.extraClass = "";
          today.tooltip = "";

          if (today.valid && opt.beforeShowDay && typeof opt.beforeShowDay == "function") {
            var _r = opt.beforeShowDay(moment(today.time).toDate());

            today.valid = _r[0];
            today.extraClass = _r[1] || "";
            today.tooltip = _r[2] || "";
            if (today.tooltip !== "") today.extraClass += " has-tooltip ";
          }

          var todayDivAttr = {
            time: today.time,
            "data-tooltip": today.tooltip,
            "class": "day " + today.type + " " + today.extraClass + " " + (today.valid ? "valid" : "invalid") + " " + (highlightToday ? "real-today" : "")
          };

          if (day === 0 && opt.showWeekNumbers) {
            html.push("<td><div class=\"week-number\" data-start-time=\"" + today.time + "\">" + opt.getWeekNumber(today.date) + "</div></td>");
          }

          html.push("<td " + attributesCallbacks({}, opt.dayTdAttrs, today) + "><div " + attributesCallbacks(todayDivAttr, opt.dayDivAttrs, today) + ">" + showDayHTML(today.time, today.day) + "</div></td>");
        }

        html.push("</tr>");
      }

      return html.join("");
    }

    function showDayHTML(time, date) {
      if (opt.showDateFilter && typeof opt.showDateFilter == "function") return opt.showDateFilter(time, date);
      return date;
    }

    function getLanguages() {
      if (opt.language == "auto") {
        var language = navigator.language ? navigator.language : navigator.browserLanguage;

        if (!language) {
          return $.dateRangePickerLanguages["default"];
        }

        language = language.toLowerCase();

        if (language in $.dateRangePickerLanguages) {
          return $.dateRangePickerLanguages[language];
        }

        return $.dateRangePickerLanguages["default"];
      } else if (opt.language && opt.language in $.dateRangePickerLanguages) {
        return $.dateRangePickerLanguages[opt.language];
      } else {
        return $.dateRangePickerLanguages["default"];
      }
    }
    /**
     * Translate language string, try both the provided translation key, as the lower case version
     */


    function translate(translationKey) {
      var translationKeyLowerCase = translationKey.toLowerCase();
      var result = translationKey in languages ? languages[translationKey] : translationKeyLowerCase in languages ? languages[translationKeyLowerCase] : null;
      var defaultLanguage = $.dateRangePickerLanguages["default"];
      if (result == null) result = translationKey in defaultLanguage ? defaultLanguage[translationKey] : translationKeyLowerCase in defaultLanguage ? defaultLanguage[translationKeyLowerCase] : "";
      return result;
    }

    function getDefaultTime() {
      var defaultTime = opt.defaultTime ? opt.defaultTime : new Date();

      if (opt.lookBehind) {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0) defaultTime = nextMonth(moment(opt.startDate).toDate());
        if (opt.endDate && compare_month(defaultTime, opt.endDate) > 0) defaultTime = moment(opt.endDate).toDate();
      } else {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0) defaultTime = moment(opt.startDate).toDate();
        if (opt.endDate && compare_month(nextMonth(defaultTime), opt.endDate) > 0) defaultTime = prevMonth(moment(opt.endDate).toDate());
      }

      if (opt.singleDate) {
        if (opt.startDate && compare_month(defaultTime, opt.startDate) < 0) defaultTime = moment(opt.startDate).toDate();
        if (opt.endDate && compare_month(defaultTime, opt.endDate) > 0) defaultTime = moment(opt.endDate).toDate();
      }

      return defaultTime;
    }

    function resetMonthsView(time) {
      if (!time) {
        time = getDefaultTime();
      }

      if (opt.lookBehind) {
        showMonth(prevMonth(time), "month1");
        showMonth(time, "month2");
      } else {
        showMonth(time, "month1");
        showMonth(nextMonth(time), "month2");
      }

      if (opt.singleDate) {
        showMonth(time, "month1");
      }

      showSelectedDays();
      showGap();
    }
  };
});

export default exports;